<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>Composições Por Etapa </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loading_table" indeterminate></v-progress-linear>
            <div class="sub_title title mb-3">
                {{ stage.code + ' - ' + stage.name }}
            </div>

            <div v-for="composition in compositions" :key="composition.id" class="mb-3">
                <div class="sub_title" >{{ composition.composition_group.code + '-' + composition.code + ' - ' + composition.name }} </div>
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-left">
                                DESCRIÇÃO
                            </th>
                            <th class="text-center">
                                UNID
                            </th>
                            <th class="text-right">
                                QUANTIDADE
                            </th>
                            <th class="text-right">
                                P UNIT (R$)
                            </th>
                            <th class="text-right">
                                SUB-TOTAL (R$)
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="input in composition.input" :key="input.id">
                            <td>{{ input.input_group.code + '-' + input.full_code + ' - ' + input.name }}</td>
                            <td class="text-center">{{ input.unity }}</td>
                            <td class="text-right">{{ formatMoney(input.pivot.quantity) }}</td>
                            <td class="text-right"> {{ formatMoney(input.value) }} </td>
                            <td class="text-right"> {{ formatMoney(input.subTotal) }} </td>
                        </tr>
                        <tr v-for="compo in composition.composition" :key="compo.id">
                            <td > {{ compo.composition_group.code + '-' + compo.code + ' - ' +compo.name }} </td>
                            <td class="text-center" > {{ compo.unity }} </td>
                            <td class="text-right" >{{ formatMoney(compo.pivot.quantity) }} </td>
                            <td class="text-right" > {{ formatMoney(compo.total) }} </td>
                            <td class="text-right" > {{ formatMoney(compo.subTotal) }} </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-right" style="background:lightgrey ;color: white"></td>
                            <td class="text-right" style="background:lightgrey ;color: white"></td>
                            <td class="text-right" style="background:lightgrey ;color: white"></td>
                            <td class="text-right" style="background:lightgrey ;color: black">ENCARGOS :</td>
                            <td class="text-right" style="background:lightgrey; color: black;"> {{
                                formatMoney(composition.charge) }} </td>
                        </tr>
                        <tr>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white"></td>
                            <td class="text-right" style="background:#1976d2 ;color: white">TOTAL:</td>
                            <td class="text-right" style="background:#1976d2; color: white;"> {{ formatMoney(calcTotal(composition))
                            }}</td>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </div>

        </v-card>

    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import service from "../service";

export default {
    data: () => ({
        stage: null,
        costId: null,
        compositions: [],
        budgetId: null,
        loading_table: false,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
        ],
    }),
    computed: {

    },
    watch: {

    },
    created() {
        this.stageId = this.$route.params.stage_id;
        this.getCompositions();
        this.stage = this.getStage()
    },
    methods: {
        ...mapGetters('ReportComposition', ['getStage']),
        getCompositions() {
            this.loading_table = true
            service
                .getCompositions(this.stageId)
                .then((result) => {
                    this.compositions = result.data;
                })
                .catch()
                .finally(() => {
                    this.loading_table = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.exportExcel(this.stageId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'composicao_por_etapa.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal(composition) {
            let totalComposition = 0
            let totalInput = 0

            totalComposition = composition.composition.reduce((acumulator, current) => {
                return acumulator + current.subTotal;
            }, 0);

            totalInput = composition.input.reduce((acumulator, current) => {
                return acumulator + current.subTotal;
            }, 0);

            return totalComposition + totalInput + composition.charge;
        },

        calcCharge() {
            let chargeComposition = 0
            chargeComposition = this.compositions.reduce((acumulator, item) => {
                return acumulator + item.tax;
            }, 0);

            // chargeComposition = this.compositions.reduce((acumulator, item) => {
            //     return acumulator + item.tax;
            // }, 0);

            return chargeComposition;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>


<style scoped>
.sub_title {
    background: #1976d2;
    color: white;
    height: 32px;
    box-sizing: border-box;
    padding-top: 4px;
    padding-left: 4px;
}


</style>
